import "./Footer.css";

const Footer = () => {
  return (
    <section className="footer-sec">
      <div className="container-site">
        <div className="footer-row">
          <div className="footer-block-one">
            <img src="/image/footerlogo.png" alt="" />
            <p>Oakville, Ontario</p>
            <a href="mailto:info@dtexperts.ca">info@dtexperts.ca</a>
          </div>
          <div className="footer-block-two">
            <h6>Our Services</h6>
            <ul className="footer-service-list">
              <li>Business Process Re-engineering</li>
              <li>Legacy Data Migration</li>
              <li>System Integration</li>
              <li>Build Secure Web Interfaces</li>
              <li>Moving to the Cloud</li>
              <li>Open Banking</li>
              <li>Enterprise Architecture</li>
              <li>Compliance and Privacy Governance</li>
              <li>IT Strategy</li>
            </ul>
          </div>
          <div className="footer-block-three">
            <h6>Contact Us</h6>
            <a href="mailto:info@dtexperts.ca">info@dtexperts.ca</a>
          </div>
        </div>
      </div>
      <div className="divider-footer">
        <p className="trade-mark">
          All Rights Reserved © 2023 Digital Transformation Experts.
        </p>
      </div>
    </section>
  );
};

export default Footer;
