import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";
import Home from "../pages/Home";



const CustomRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Home />} />                 
        </Route>
      </Routes>
    </Router>
  );
};

export default CustomRouter;
