const TopNavBar = () => {
  return (
    <header>
    <div className="container-site">
        <div className="header-logo-row">
            <img src="/image/dtxlogo.png" alt=""/>
        </div>
    </div>
</header>
  );
};

export default TopNavBar;
