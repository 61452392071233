import React from "react";
import Banner from "../../components/Home/Banner/Banner";
import OurServices from "../../components/Home/OurServices/OurServices";
import Experts from "../../components/Home/Experts/Experts";
import Business from "../../components/Home/Business/Business";

const Home = () => {
  return (
    <>
      <Banner />
      <OurServices />
      <Experts />
      <Business />
    </>
  );
};

export default Home;
