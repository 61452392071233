import "./OurServices.css";

const OurServices = () => {
  return (
    <>
      <section className="service-sec">
        <div className="container-site">
          <div className="service-heading">
            <h2>Our Services</h2>
          </div>
        </div>
      </section>

      <section className="service-card-sec">
        <div className="container-site">
          <div className="services-cards-row">
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Business Process Re-engineering </h4>
                <p>
                  Streamline and optimize your business processes for improved
                  efficiency, reduced costs, and enhanced customer experiences.
                  Our experts will analyze your existing workflows and identify
                  opportunities for automation, simplification, and innovation.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Legacy Data Migration</h4>
                <p>
                  Safely and efficiently migrate your valuable data from legacy
                  systems to modern platforms. We have extensive experience in
                  data migration projects, ensuring seamless transition while
                  preserving data integrity and security.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>System Integration</h4>
                <p>
                  Seamlessly connect and integrate your disparate systems to
                  improve data flow, enhance operational efficiency, and enable
                  real-time insights. Our integration solutions bring your
                  applications, databases, and third-party systems together,
                  enabling you to harness the full potential of your technology
                  stack.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Build Secure Web Interfaces</h4>
                <p>
                  Develop secure and user-friendly web interfaces that deliver
                  exceptional digital experiences for your customers and
                  employees. From web portals to mobile applications, we
                  leverage the latest technologies to create intuitive
                  interfaces that drive engagement and productivity.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Moving to the Cloud</h4>
                <p>
                  Harness the power of cloud computing to unlock scalability,
                  flexibility, and cost savings. Our experts will guide you
                  through the cloud adoption journey, helping you select the
                  right cloud platforms, migrate your applications, and optimize
                  your cloud infrastructure for optimal performance.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Open Banking</h4>
                <p>
                  Embrace the opportunities presented by open banking
                  initiatives. Our team will assist you in leveraging open APIs,
                  creating new revenue streams, and delivering innovative
                  financial services that meet the evolving needs of your
                  customers.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Enterprise Architecture</h4>
                <p>
                  Develop a robust and future-proof enterprise architecture that
                  aligns with your business strategy. Our architects will design
                  a scalable and modular architecture that supports your digital
                  initiatives and enables seamless integration of new
                  technologies.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>Compliance and Privacy Governance</h4>
                <p>
                  Ensure your digital transformation adheres to regulatory
                  requirements and best practices. We provide comprehensive
                  compliance and privacy governance solutions, helping you
                  navigate complex regulations and protect sensitive data.
                </p>
              </div>
            </div>
            <div className="featured-cards-col">
              <div className="featured-content">
                <img src="/image/iCON.png" alt="" />
                <h4>IT Strategy</h4>
                <p>
                  Ensuring ongoing relevance by keeping up with emerging
                  technologies, while identifying and recommending innovative
                  technologies to drive business growth through comprehensive
                  roadmaps and business cases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
