import "./Experts.css";

const Experts = () => {
  return (
    <>
      <section className="service-sec">
        <div className="container-site">
          <div className="choose-sec-heading">
            <h3>Why Choose Digital Transformation Experts?</h3>
          </div>
        </div>
      </section>

      <section className="expert-list-sec">
        <div className="container-site">
          <div className="expert-list-row">
            <div className="expert-list-content">
              <ul className="list-content">
                <li>
                  <strong>Extensive Experience:</strong> With a proven track
                  record and deep industry expertise, we have successfully
                  helped numerous companies across various sectors embark on
                  their digital transformation journeys.
                </li>
                <li>
                  <strong>Tailored Solutions:</strong> We understand that every
                  organization is unique. Our solutions are customized to meet
                  your specific needs and challenges, ensuring the best outcomes
                  for your business.
                </li>
                <li>
                  <strong>Technology Excellence:</strong> We stay at the
                  forefront of technology trends and innovations. By leveraging
                  cutting-edge tools and platforms, we enable you to embrace
                  digital opportunities and gain a competitive edge.
                </li>
                <li>
                  <strong>Collaborative Approach:</strong> We believe in building
                  strong partnerships with our clients. Our collaborative
                  approach ensures that we work closely with you, understanding
                  your goals and challenges to deliver impactful results.
                </li>
              </ul>
            </div>
            <div className="expert-list-img">
              <img src="/image/atom.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experts;
