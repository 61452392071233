import { Link } from "react-router-dom";
import "./Banner.css";

const Banner = () => {
  return (
    <section className="banner-sec">
      <div className="container-site">
        <div className="banner-featured">
          <div className="featured-text">
            <h1>
              Unlocking Your <br />
              <span> Digital Potential</span>
            </h1>
            <p className="banner-featured-content">
              Are you ready to transform your business for the digital age? Look
              no further! Digital Transformation Experts is here to guide you
              through the complex journey of digital transformation, providing
              you with tailored solutions to drive growth, efficiency, and
              innovation.
            </p>
            <a href="#contact_us">
              <button>Contact Us</button>
            </a>
          </div>
          <div className="featured-img">
            {/* <!-- <img src="./image/banner.png" alt=""> --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
