import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./Business.css";
import { BaseURL } from "../../../constants";

const Business = () => {
  const [loader, setLoader] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("Email Sent Successfully");
  const formik = useFormik({
    initialValues: {
      txtName: "",
      email: "",
      detail: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      txtName: Yup.string().required("Name Required"),
      email: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Please enter valid email address"
        )
        .required("Email Required"),
      detail: Yup.string().required("Message Required"),
    }),
    onSubmit: async (data, { resetForm }) => {
      try {
        setLoader(true);
        const obj = {
          name: data.txtName,
          email: data.email,
          message: data.detail,
        };

        const res = await axios.post(BaseURL + "/email.php", obj, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
            "Content-Type": "application/json",
          },
        });
        console.log("res", res);
        if (res.status === 200) {
          setLoader(false);
          setShowMsg(true);
          setMsg(res.data?.message);
          hideMsg();
          resetForm();
        } else if (res.status === 400 || res.status === 500) {
          setLoader(false);
          setShowMsg(true);
          setMsg(res.data?.message);
          hideMsg();
          resetForm();
        }
      } catch (err) {
        console.log("res", err);
        setLoader(false);
        resetForm();
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const hideMsg = () => {
    setTimeout(() => {
      setLoader(false);
      setShowMsg(false);
      setMsg("");
    }, 5000);
  };

  return (
    <>
      <section className="service-sec" id="contact_us">
        <div className="container-site">
          <div className="choose-sec-heading">
            <h3>Ready to Transform Your Business?</h3>
          </div>
        </div>
      </section>

      <section className="form-sec">
        <div className="container-site">
          <div className="form-logo-row">
            <div className="msg-logo-content">
              <img src="/image/msg-icon.png" alt="" />
              <h5>
                Contact us today to schedule a consultation with our digital
                transformation experts
              </h5>
              <p>
                Let us guide you through the exciting journey of digital
                transformation, helping you unlock your full potential in the
                digital age.
              </p>
            </div>
            <div className="form-col">
              <div className="bussniess-form">
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`f-feild  ${
                      isFormFieldValid("txtName") && "error-input"
                    }`}
                  >
                    <input
                      type="text"
                      name="txtName"
                      value={formik.values.txtName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="name-f"
                      placeholder="Name"
                    />
                    {getFormErrorMessage("txtName")}
                  </div>
                  <div
                    className={`f-feild  ${
                      isFormFieldValid("email") && "error-input"
                    }`}
                  >
                    <input
                      type="email"
                      name="email"
                      id="email-f"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("email")}
                  </div>

                  <div
                    className={`f-feild  ${
                      isFormFieldValid("detail") && "error-input"
                    }`}
                  >
                    <textarea
                      name="detail"
                      id="textarea-f"
                      cols="30"
                      rows="15"
                      placeholder="Type here......."
                      value={formik.values.detail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {getFormErrorMessage("detail")}
                  </div>

                  <button type="submit" id="btn-sumbit">
                    {loader && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {"  "} Send Message
                  </button>
                </form>
                <div>{showMsg && <span className="sub-msg">{msg}</span>}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Business;
